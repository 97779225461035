<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header height="90px" class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="16">
              <el-button type="primary" icon="el-icon-upload2" @click="uploadNewFileOnclick">上传</el-button>
              <el-button icon="el-icon-folder-add" @click="newFolderDialogVisible = true">新建文件夹</el-button>
              <!-- <el-button type="primary" icon="el-icon-upload2" @click="filePasteString"
                >粘贴</el-button
              >-->
              <el-button type="primary" icon="el-icon-delete" @click="fileDeleteOnclick">批量删除</el-button>
            </el-col>
            <el-col style="text-align: right" :span="8">
              <el-input
                v-model="search_key"
                style="width: 220px; margin-right: 10px"
                placeholder="搜索当前文件夹"
                clearable
              ></el-input>
              <el-button type="primary" icon="el-icon-search" @click="getFileList">搜索</el-button>
            </el-col>
          </el-row>
        </div>
        <el-breadcrumb
          class="catalog-link"
          separator-class="el-icon-arrow-right"
          style="margin:15px"
        >
          <el-breadcrumb-item
            v-for="(item, i) in catalog_list"
            :to="{ path: file_prefix + item.id }"
            replace
            :key="i"
          >{{ item.name }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-header>
      <el-main>
        <div style="text-align: left; margin-left: 20px">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
          >全选</el-checkbox>
        </div>
        <div style="margin: 15px 0"></div>
        <li class="file-li" v-for="(item, i) in file_list" :label="item.id" :key="i">
          <div style="width: 100%; height: 20px">
            <el-button class="file-more" icon="el-icon-more" @click="fileMoreOnclick(item)"></el-button>
          </div>
          <div style="width: 100%; cursor: pointer" @click="fileOneClick(item)">
            <div style="width: 100%; height: 95px">
              <el-image
                v-if="isImage(item.file_type) === false"
                class="file-icon"
                fit="contain"
                :src="item.file_icon + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
              ></el-image>
              <el-image
                v-if="isImage(item.file_type)"
                class="file-icon"
                fit="contain"
                :src="item.file_url + $common.K_QINIU_IMAGE_SMALL_END_100_PX"
                :preview-src-list="[item.file_url]"
              ></el-image>
            </div>
          </div>
          <!-- <div class="select_file">
            {{ item.name }}
          </div>-->
          <el-checkbox-group v-model="choose_file" @change="handleCheckedCitiesChange">
            <el-checkbox :label="item.id" class="select_file">{{ item.name }}</el-checkbox>
          </el-checkbox-group>
        </li>
      </el-main>
    </el-container>
    <!-- 新建文件夹弹窗 -->
    <el-dialog title="新建文件夹" :visible.sync="newFolderDialogVisible" width="30%">
      <el-form :model="newFolderModel" :rules="rules" label-width="100px">
        <el-form-item label="文件夹名称:" prop="name">
          <el-input v-model="newFolderModel.name" placeholder="请输入文件夹名称(请勿使用特殊字符)" clearable></el-input>
        </el-form-item>
        <el-form-item label="文件夹别名:">
          <el-input v-model="newFolderModel.alias" placeholder="非中文和特殊字符的文件夹别名可自动生成" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="newFolderDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="newFolderOnclick">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 文件上传进度弹窗-->
    <el-dialog
      title="文件上传进度"
      :visible.sync="uploadFileDialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <ul style="margin: 0; padding: 0">
        <li
          v-for="(item, i) in uploadFileDialogFileList"
          style="list-style: none; margin: 0; padding: 0"
          :key="i"
        >
          <p style="text-align: left; margin-left: 15px">{{ item.name }}</p>
          <el-progress
            :text-inside="true"
            :percentage="item.percentage"
            :stroke-width="20"
            :status="item.status"
          ></el-progress>
        </li>
      </ul>
      <div slot="footer">
        <el-button type="primary" @click="updateDialogSuccessOnclick">完成</el-button>
      </div>
    </el-dialog>
    <!-- 文件详情弹窗-->
    <el-dialog title="文件详情" :visible.sync="fileInfoDialogVisible" width="50%">
      <el-form :model="fileInfoDialogModel" label-width="100px">
        <el-form-item label="文件ID:">
          <el-input v-model="fileInfoDialogModel.id" disabled clearable></el-input>
        </el-form-item>
        <el-form-item label="文件名称:" prop="name">
          <el-input
            v-model="fileInfoDialogModel.name"
            placeholder="请输入文件名称(请勿使用特殊字符)"
            :disabled="!fileInfoDialogModel.is_folder"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="文件别名:">
          <el-input
            v-model="fileInfoDialogModel.alias"
            placeholder="非中文和特殊字符的文件别名可自动生成"
            disabled
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-if="fileInfoDialogModel.is_folder === false" label="文件链接:">
          <el-input
            style="width: calc(100% - 110px)"
            v-model="fileInfoDialogModel.file_url"
            disabled
            clearable
          ></el-input>
          <el-button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="fileCopyString(fileInfoDialogModel.file_url)"
          >复制</el-button>
        </el-form-item>
        <el-form-item v-if="fileInfoDialogModel.is_folder === false" label="文件大小:">
          <el-input
            style="width: calc(100% - 110px)"
            v-model="fileInfoDialogModel.file_size"
            disabled
            clearable
          ></el-input>
          <el-button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="fileCopyString(fileInfoDialogModel.file_size)"
          >复制</el-button>
        </el-form-item>
        <el-form-item v-if="fileInfoDialogModel.is_folder === false" label="七牛KEY:">
          <el-input
            style="width: calc(100% - 110px)"
            v-model="fileInfoDialogModel.file_hash"
            disabled
            clearable
          ></el-input>
          <el-button
            style="width: 100px; margin-left: 10px"
            type="primary"
            @click="fileCopyString(fileInfoDialogModel.file_hash)"
          >复制</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <!--                <el-button v-if="fileInfoDialogModel.is_folder === false" type="danger" @click="fileDeleteOnclick">删除</el-button>-->
        <el-button type="danger" @click="filePasteAll(fileInfoDialogModel)">复制整个文件信息</el-button>
        <el-button type="danger" @click="fileDeleteOnclick(fileInfoDialogModel)">删除</el-button>
        <el-button v-if="fileInfoDialogModel.is_folder" type="warning" @click="fileUpdateOnclick">保存</el-button>
        <el-button type="primary" @click="fileInfoDialogVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 文件选择 -->
    <input
      type="file"
      multiple
      style="display: none"
      @change="chooseFileChangeOnclick"
      ref="fileInput"
    />
  </div>
</template>

<script>
export default {
  name: "FileListView",
  data() {
    return {
      loading: false,
      search_key: "",
      parent_id: "0",
      page_end_id: "",
      page_size: -1,
      file_prefix: "/file?id=",
      catalog_list: [
        {
          id: "0",
          name: "全部文件"
        }
      ],
      file_list: [],
      newFolderDialogVisible: false,
      newFolderModel: {
        name: "",
        alias: ""
      },
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        alias: [
          { required: true, message: "请输入别名", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      },
      uploadFileDialogVisible: false,
      uploadFileDialogFileList: [],
      fileInfoDialogVisible: false,
      fileInfoDialogModel: {},
      pastFileString: "",
      isIndeterminate: true,
      checkAll: false,
      choose_file: []
    };
  },
  watch: {
    $route(n, o) {
      //监听路由参数是否变化
      if (n.path === o.path) {
        let file_id = n.query.id;
        if (file_id !== undefined && file_id.length > 0) {
          this.parent_id = file_id;
        } else {
          this.parent_id = "0";
        }
        this.getFileList();
      }
    }
  },
  created() {
    let file_id = this.$route.query.id;
    if (file_id !== undefined && file_id.length > 0) {
      this.parent_id = file_id;
    } else {
      this.parent_id = "0";
    }
    this.getFileList();
  },
  methods: {
    handleCheckAllChange(val) {
      let array = [];
      this.file_list.forEach(v => {
        array.push(v.id);
      });
      this.choose_file = val ? array : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.file_list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.file_list.length;
    },
    getFileList() {
      let _me = this;
      _me.loading = true;
      let params = {
        key: _me.search_key,
        parent_id: _me.parent_id,
        page_id: _me.page_end_id,
        page_flag: 2,
        page_size: _me.page_size,
        sort_fields: "is_folder,-created_at,name,-id"
      };
      _me.$common
        .httpPost("/api/file/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.file_list = res.data.array === undefined ? [] : res.data.array;
            _me.catalog_list = res.data.file_path.reverse();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    isImage(fileType) {
      if (fileType.length === 0) {
        return false;
      } else {
        let reg = new RegExp("^image/[a-z]*$");
        return reg.test(fileType);
      }
    },
    newFolderOnclick() {
      // 新建文件夹
      let _me = this;
      _me.newFolderDialogVisible = false;
      if (_me.newFolderModel.name.length === 0) {
        _me.$message.error("文件夹名称不能为空");
      } else {
        _me.loading = true;
        let params = {
          parent_id: _me.parent_id,
          name: _me.newFolderModel.name,
          alias: _me.newFolderModel.alias
        };
        _me.$common
          .httpPost("/api/file/admin/add/folder", params)
          .then(res => {
            _me.loading = false;
            if (res.code === "1000000") {
              _me.getFileList();
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error"
              });
            }
          })
          .catch(err => {
            _me.loading = false;
            _me.$message({
              message: err || "获取数据失败",
              type: "error"
            });
          });
      }
    },
    fileOneClick(item) {
      if (item.is_folder === true) {
        this.$router.replace("/file?id=" + item.id);
      }
    },
    fileMoreOnclick(item) {
      this.fileInfoDialogVisible = true;
      this.fileInfoDialogModel = item;
    },
    fileDeleteOnclick(value) {
      let _me = this;
      _me.fileInfoDialogVisible = false;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(value);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        ids: ""
      };

      if (model && model != undefined && model.id) {
        params.ids = model.id;
      } else {
        params.ids = _me.choose_file;
      }

      _me.$common
        .httpPost("/api/file/admin/delete", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getFileList();
            _me.choose_file = [];
            _me.checkAll = false;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    fileUpdateOnclick() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.fileInfoDialogModel.id,
        name: _me.fileInfoDialogModel.name
      };
      _me.$common
        .httpPost("/api/file/admin/update/folder/name", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("修改成功");
            _me.fileInfoDialogVisible = false;
            _me.fileInfoDialogModel = {};
            _me.getFileList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    // 获取复制后粘贴板的信息
    filePasteString() {
      let _me = this;
      navigator.clipboard
        .readText()
        .then(v => {
          // 获取剪贴板成功：正常字符串不能用JSON.pares解析
          this.pastFileString = JSON.parse(v);
          //   console.log(this.pastFileString);
          let pasteParams = [
            {
              parent_id: this.pastFileString.parent_id,
              name: this.pastFileString.name,
              file_type: this.pastFileString.file_type,
              file_size: this.pastFileString.file_size,
              file_info: this.pastFileString.file_info
            }
          ];
          let params = {
            model_list: JSON.stringify(pasteParams)
          };

          // 判断要复制的文件路径是否与当前路径一致
          //   1.先与当前文件列表的信息进行对比，相同路径就直接添加到当前文件列表；不相同路径的文件夹下就提示当前路径与复制过来的文件路径不符合，提示信息文件路径不符合，请上传文件
          if (
            this.pastFileString.file_location != this.file_list[0].file_location
          ) {
            this.$alert("复制的文件与当前路径不符合, 请重新上传！", "", {
              confirmButtonText: "确定",
              type: "warning"
            });
          } else {
            //   相同路径匹配，简历文件基本信息
            _me.$common
              .httpPost("/api/file/admin/build/info/list", params)
              .then(res => {
                _me.loading = false;
                if (res.code === "1000000") {
                  let newParams = {
                    alias: this.pastFileString.alias,
                    file_hash: this.pastFileString.file_hash,
                    file_icon: this.pastFileString.file_icon,
                    file_id: this.pastFileString.file_id,
                    file_info: this.pastFileString.file_info,
                    file_key: this.pastFileString.file_key,
                    file_location: this.pastFileString.file_location,
                    file_size: this.pastFileString.file_size,
                    file_suffix: this.pastFileString.file_suffix,
                    file_type: this.pastFileString.file_type,
                    file_url: this.pastFileString.file_url,
                    is_folder: this.pastFileString.is_folder,
                    name: this.pastFileString.name,
                    parent_id: this.pastFileString.parent_id
                  };
                  // 添加新的文件
                  _me.$common
                    .httpPost("/api/file/admin/add/file", newParams)
                    .then(res => {
                      if (res.code === "1000000") {
                        //   添加成功重新获取文件列表
                        this.getFileList();
                        _me.$message({
                          message: res.message || "文件添加成功",
                          type: "success"
                        });
                      } else {
                        _me.$message({
                          message: res.message || "获取数据失败",
                          type: "error"
                        });
                      }
                    })
                    .catch(err => {
                      _me.$message({
                        message: err || "获取数据失败",
                        type: "error"
                      });
                    });
                } else {
                  _me.$message({
                    message: res.message || "获取数据失败",
                    type: "error"
                  });
                }
                // return;
                // 对比别名，id   相同就直接添加，不同就做比较
              })
              .catch(res => {
                _me.loading = false;
                _me.$message({
                  message: res.message || "获取数据失败",
                  type: "error"
                });
              });
          }
        })
        .catch(v => {
          // 获取剪贴板失败 提示消息失败
          _me.$message.error("获取剪贴板失败");
        });
    },
    // 复制整个文件信息
    filePasteAll(str) {
      let string = JSON.stringify(str);
      let _me = this;
      if (_me.$common.copyText(string)) {
        _me.$message.success("复制成功");
      } else {
        _me.$message.error("复制失败");
      }
    },
    fileCopyString(str) {
      let _me = this;
      if (_me.$common.copyText(str)) {
        _me.$message.success("复制成功");
      } else {
        _me.$message.error("复制失败");
      }
    },
    uploadNewFileOnclick() {
      let fileInput = this.$refs.fileInput;
      fileInput.value = null;
      fileInput.click();
    },
    updateDialogSuccessOnclick() {
      this.uploadFileDialogVisible = false;
      // this.getFileList();
    },
    chooseFileChangeOnclick() {
      let _me = this;
      let inputFileList = _me.$refs.fileInput.files;
      if (inputFileList !== undefined && inputFileList.length > 0) {
        let checkFileModelList = [];
        // 解析文件
        for (let i = 0; i < inputFileList.length; i++) {
          let file = inputFileList[i];
          if (file.size === 0) {
            _me.$message.error("文件(" + file.name + ")为损坏文件请重新选择!");
            return;
          }
          if (file.type.slice(-3) === "psd") {
            _me.$alert("不能上传psd文件！", "", {
              confirmButtonText: "确定",
              type: "warning"
            });
            return;
          }
          let checkFileModel = {
            parent_id: _me.parent_id,
            name: file.name.replace(/\s/g, "_"),
            file_type: file.type,
            file_size: file.size,
            file_info: ""
          };
          checkFileModelList.push(checkFileModel);
        }

        // 从服务器申请上传文件
        _me.buildUploadFileInfo(inputFileList, checkFileModelList);
      }
    },
    buildUploadFileInfo(inputFileList, checkFileModelList) {
      // console.log("上传文件", inputFileList)
      // console.log("从服务器申请上传文件", checkFileModelList)
      let _me = this;
      _me.loading = true;
      let params = {
        model_list: JSON.stringify(checkFileModelList)
      };
      _me.$common
        .httpPost("/api/file/admin/build/info/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.uploadFileList(inputFileList, res.data);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    async uploadFileList(inputFileList, checkFileModelList) {
      console.log("上传文件", inputFileList);
      console.log("从服务器申请上传文件", checkFileModelList);
      let _me = this;
      _me.uploadFileDialogFileList = [];
      for (let i = 0; i < checkFileModelList.length; i++) {
        _me.uploadFileDialogFileList.push({
          name: checkFileModelList[i].name,
          percentage: 0,
          status: "warning"
        });
      }
      _me.uploadFileDialogVisible = true;
      try {
        for (let j = 0; j < inputFileList.length; j++) {
          const file = inputFileList[j];
          const fileKey = checkFileModelList[j].file_key;

          const hash = await new Promise((resolve, reject) => {
            _me.$common.qiniuUploadImage(
              file,
              fileKey,
              function(res) {
                console.log(
                  "文件：" +
                    file.name +
                    " 当前上传进度，范围：0～100：" +
                    res.total.percent
                );
                _me.uploadFileDialogFileList[j].percentage = res.total.percent;
              },
              function(err, data) {
                if (err === undefined) {
                  console.log("上传文件结束", data);
                  resolve(data.hash);
                } else {
                  reject(err);
                }
              }
            );
          });

          checkFileModelList[j].file_hash = hash;
          let params = checkFileModelList[j];

          const res = await _me.$common.httpPost(
            "/api/file/admin/add/file",
            params
          );

          if (res.code === "1000000") {
            _me.uploadFileDialogFileList[j].status = "success";
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
            _me.uploadFileDialogFileList[j].status = "exception";
          }
        }
        this.uploadFileDialogVisible = false;
        this.getFileList();
      } catch (err) {
        _me.$message({
          message: err || "上传文件失败",
          type: "error"
        });
      }
    }
  }
};
</script>

<style scoped>
.head-info {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  color: #ffffff;
}

.banner-head {
  background-color: #f1f1f1;
}

.catalog-link {
  width: 100%;
  /* padding: 15px; */
  background-color: #f1f1f1;
}

.file-li {
  width: 120px;
  height: 150px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 4px;
  float: left;
  list-style: none;
}

.file-more {
  width: 30px;
  height: 20px;
  margin: 0;
  padding: 0;
  float: right;
  background: white;
  border-style: none;
  display: none;
}

.file-li:hover {
  background-color: #eef2f9;
}

.file-li:hover .file-more {
  display: block;
  background-color: #eef2f9;
}

.file-icon {
  width: 90px;
  height: 90px;
}

.select_file {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 5px 0 5px;
  padding-top: 6px;
  font-size: 11pt;
  width: 100%;
}

.select_file >>> .el-checkbox__label {
  display: inline;
  width: 100%;
}
</style>
